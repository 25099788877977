<template>
  <div>
    <section class="main">
      <div class="home-section position-relative">
        <div class="text-white container col-lg-6 col-md-12 col-sm-12">
          <div style="padding-top: 150px">
            <small>
              <a href="/web/home" class="text-white"> Home </a> /
              <a href="/web/news" class="text-white">News</a> /
              {{ article.title }}
            </small>
          </div>
          <h1 class="f-secondary text-white font-40 my-5">
            {{ article.title }}
          </h1>
          <p class="font-25">
            {{ article.description }}
          </p>
        </div>
        <div class="center position-relative" style="padding-top: 100px">
          <img :src="article.image" class="img-fluid border-radius-40" />
        </div>
      <div class="white-section"></div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 mx-auto">
        <!-- Share starts-->
        <div
          class="my-5 d-flex justify-content-between align-items-center w-100 w-md-50 mx-auto"
        >
          <div class="d-flex align-items-center">
            <img
              class="circle-img"
              src="../../../public/img/stm/article-writer.png"
            />
            <div class="d-inline-flex flex-column ml-3">
              <h4 class="bold">By {{ article.postedBy }}</h4>
              <small> {{ article.dateTime }}</small>
            </div>
          </div>
          <div class="d-flex align-items-center flex-column">
            <h5 class="mb-0 mb-2">Share</h5>
            <div>
              <a
                href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ftalentfind.com%2Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
                class="mr-2"
              >
                <img src="../../../public/img/stm/facebook.svg" />
              </a>
              <a
                href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Furl%3Dhttps%253A%252F%252Ftalentfind.com%252Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
              >
                <img src="../../../public/img/stm/linkedin.svg" />
              </a>
            </div>
          </div>
        </div>
        <div class="my-3">
          <hr />
        </div>
        <!-- Share ends -->

        <!-- Content -->
        <component :is="article.contentFileName" />
        <!-- Content ends -->

        <!-- Share starts -->
        <div class="d-flex align-items-start flex-column">
          <h5 class="mb-0 mb-2">Share</h5>
          <div>
            <a
              href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ftalentfind.com%2Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
              class="mr-2"
            >
              <img src="../../../public/img/stm/facebook.svg" />
            </a>
            <a
              href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Furl%3Dhttps%253A%252F%252Ftalentfind.com%252Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
            >
              <img src="../../../public/img/stm/linkedin.svg" />
            </a>
          </div>
        </div>
        <div class="my-3">
          <hr />
        </div>
        <!-- Share end -->
        <div>
          <h1 class="f-secondary font-40 bold text-dark-primary">
            You Might Also Like...
          </h1>

          <div>
            <div class="row">
              <div
                v-for="(article, index) in like_articles"
                :key="index"
                class="card col-12 col-md-6 cursor-pointer"
                @click="
                  $router.push(`/web/article/${article.id}`);
                  $router.go(0);
                "
              >
                <img
                  :src="article.image"
                  class="card-img-top border-radius-40 mt-5"
                  :alt="article.altText"
                />
                <div class="mt-3 card-body">
                  <p class="f-secondary font-25 bold">
                    {{ article.title }}
                  </p>
                  <p>{{ article.description }}</p>
                </div>
                <div class="d-flex align-items-center card-footer">
                  <img
                    class="circle-img"
                    src="../../../public/img/stm/article-writer.png"
                  />
                  <div class="d-inline-flex flex-column ml-3">
                    <span class="bold">{{ article.postedBy }}</span>
                    <small> {{ article.dateTime }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <NewFooter />
    <ScrollToTop />
  </div>
</template>

<script>
import articles from "./articles.json";
import Article1Content from "./Article1Content.vue";
import Article2Content from "./Article2Content.vue";
import Article3Content from "./Article3Content.vue";
import NewFooter from "./NewFooter.vue";
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";

export default {
  components: {
    Article1Content,
    Article2Content,
    Article3Content,
    NewFooter,
    ScrollToTop,
  },
  data() {
    return {
      article: null,
      like_articles: [],
    };
  },
  created() {
    const id = parseInt(this.$route.params.id);
    if (!isNaN(id) && id >= 0 && id <= articles.length) {
      this.article = articles[id - 1] || null;
      this.like_articles = articles.filter((article) => {
        return article.id != id;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
